import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import manoir2 from './assets/IMG_3670 (1).JPG';
import Home from "./routes/Home";
import Bedroom from './routes/Bedroom';
import HeaderImageContainer from './components/HeaderImageContainer';
import Layout from './components/Layout';

class NotFound extends Component {
  componentWillMount() {
    window.location.assign('/');
  }

  render() {
    return null;
  }
}

const AboutUs = () => (
  <Layout>
    <HeaderImageContainer image={"https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/manoir-01.JPG"} title={"Nous vous préparons un accueil chaleureux au Manoir des Lords."}/>
    <div>
      <h1 className="TitleHome">Qui sommes-nous ?</h1>
      <div className="content">
        <p>
          Originaire du Pays d’Auge et amoureux de vieilles pierres, nous avons repris cette maison en
          2023.
          <br/>
          Nous avons à cœur de faire vivre ce domaine et de lui redonner pleinement son âme.
        </p>
        <p>
          Chaque gîte vous permettra d’avoir un espace privé au sein de cette propriété entourée de 2,5 hectares
          de parc.
        </p>
        <p>
          Nous serons heureux, grâce aux gîtes, que vous puissiez goûter le charme de ces lieux et
          découvrir aussi toutes les richesses de ce magnifique coin de France, tant naturelles, que culturelles,
          historiques, gastronomiques et équestres…
        </p>
      </div>
    </div>
  </Layout>
);

const Event = () => (
  <Layout>
    <HeaderImageContainer image={manoir2} title={"Vous souhaitez réserver le domaine complet pour un évènement ?"}/>
    <div className="content">
      <div className="divEvent">
        <h3>Description du Manoir des lords</h3>
        <p>blabla</p>
        <h3>Espaces et capacités</h3>
        <p>blabla</p>
        <h3>Prestations</h3>
        <p>blabla</p>
        <h3>A votre disposition au Manoir des Lords :</h3>
        <p>blabla</p>
        <h3>Logement</h3>
        <p>blabla</p>
        <h3>Histoire</h3>
        <p>blabla</p>
      </div>
    </div>
    <div className="content">
      <div className="divEvent">
        <h3>Plus d'information sur le Manoir des Lords</h3>
        <p>Quelle est la configuration de la réception ? <span>Debout, assis</span></p>
        <p>Quel type de réception proposez-vous
          ? <span>Vin d'honneur, réception, cocktail, buffet, dîner, apéritif</span></p>
        <p>Disposez-vous d'une piste de danse ? <span>Oui</span></p>
        <p>Célébrez-vous plus d'un événement par jour ?<span> Non</span></p>
        <p>Proposez vous un menu ?</p>
        <p>Est-il possible de louer le lieu, sans inclure les services du traiteur ? <span>Oui</span></p>
        <p>Disposez-vous d'un service traiteur ou d'une cuisine privée ? <span>Oui</span></p>
        <p>Quel type de gastronomie proposez-vous ? <span>Traditionnelle, internationale, cuisine créative, régionale, autres (médiévale)</span>
        </p>
        <p>Est-il possible d'adapter ou de modifier les menus ? <span>Oui</span></p>
        <p>Proposez-vous des menus spécifiques ? Végétariens, végétaliens, sans gluten, diabétique, hypertension, autres
          (menus Médiévaux)</p>
        <p>Proposez-vous des gâteaux de mariage ? Oui</p>
        <p>Puis-je apporter mon propre gâteau de mariage ? Facturez-vous une majoration ? Si le Château de Chalabre est
          loué sans le service traiteur alors vous pouvez amener votre propre gâteau de mariage sans aucune majoration.
          Sinon les gâteaux seront réalisés par notre traiteur</p>
        <p>Quelles sont les formules boissons ? Elles sont incluses dans le menu</p>
        <p>Puis-je amener mes propres boissons ? Oui, aucun droit au bouchon</p>
        <p>Y'a-t-il une limite horaire à respecter pour l'événement ? Oui, 05:00</p>
        <p>Le photographe est-il imposé ? Non</p>
        <p>Exclusivité musique: Non</p>
        <p>Le traiteur est-il imposé ? Non</p>
        <p>Existe-t-il un prix fixe pour louer le lieu ? Oui, 3200 €</p>
        <p>Dans le cas d'un nombre minimum d'invités, à combien s'élève le supplément ? Aucun supplément</p>
        <p>Comment s'effectue le paiement ? 30 % Acomptes à la commande (virements bancaires, chèques, carte bleue) Le
          solde 1 mois avant l’événement (virements bancaires)</p>
        <p>Les lieux sont-ils adaptés aux personnes handicapées ? Oui</p>
      </div>
    </div>
  </Layout>
);

const HouseHistory = () => {
  return (
    <Layout>
      <HeaderImageContainer image={"https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/manoir-01.JPG"}
                            title={"Un domaine riche en histoire, l’histoire du manoir débute au XVIème siècle..."}/>
      <h1 className="TitleHome">L'histoire du domaine</h1>
      <div className="content">
        <p className="divEvent">
          L’histoire du manoir débute au XVIème siècle. De cette époque, on connait l’existence d’un
          bâtiment à pans de bois et de son pressoir (actuellement transformé en gîte ) près de l’ancienne église
          du Pontallery, aujourd’hui disparue. Ce bâtiment fut la base de la construction du manoir actuel par un
          Lord Anglais en 1795. Il y ajouta divers éléments qui donnent aujourd’hui au manoir sa spécificité, tel
          que le clocheton qui habille le balcon au-dessus de l’entrée, les croisillons de bois ainsi que les
          faïences et briques de Saint Jean qui ornent la façade. Cela confère ainsi au bâtiment un aspect
          précurseur du style anglo-normand.</p>
        <p className="divEvent">
          Un siècle plus tard, le manoir se dote de sa tourelle qui lui permet de s’équiper à l’eau
          courante. Devenu maison de famille, ses propriétaires firent construire une aile en style anglo-normand
          qui fut détruite par la suite. Une pierre matérialise cet ancien bâtiment sur la façade. C’est aussi à cette
          époque que fut dessiné le jardin dans un style à l’anglaise, structure qu’il conserve encore aujourd’hui.
        </p>
        <p className="divEvent">
          En 1900, la charpente innovante de l’Atelier qui permet le passage de nouveaux engins
          agricoles est présentée à l’Exposition Universelle.</p>
        <p className="divEvent">
          Lors de la seconde Guerre Mondiale, le manoir est occupé par des soldats et officiers
          allemands. D’où les trous d’obus que vous pourrez voir près de l’écurie.</p>
        <p className="divEvent">
          En 2005, un industriel Anglais entreprend de restaurer les dépendances. Travail qui sera
          achevé par les anciens propriétaires, Mr et Mme Furet, qui ont su redonner vie à cette belle demeure
          endormie.
        </p>
      </div>
    </Layout>
  )
}

const Activity = () => {
  return (
    <Layout>
      <HeaderImageContainer image={"https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/manoir-02.JPG"}
                            title={"Un domaine au centre de nombreuses activités..."}/>
      <h1 className="TitleHome">Quelles activités ?</h1>
      <div className="content">
        <div className="divEvent">
          <p className="margin-top">Sur place</p>
          <p>Vous pouvez vous promener dans le parc, profiter du calme et d’une belle et large vue sur le bocage
            normand. Dans le parc, un chêne tricentenaire, un catalpa tortueux et un majestueux platane d’Orient
            attireront peut-être votre attention.</p>
          <p>Des jeux sont à votre disposition dans le jardin : une table de ping-pong, un terrain de volley, un
            terrain de pétanque, une balançoire, des vélos etc...</p>
          <p>Chaque gîte dispose d’une terrasse privative bien exposée. Elle est aménagée de table, chaises et
            parasol et d’un barbecue.
          </p>

          <p className="margin-top">Randonnées</p>
          <p>De belles promenades en plein cœur de la vallée de la Vie vous attendent.</p>
          <a href="https://www.terredauge.fr/je-bouge-je-me-cultive/les-chemins-de-randonnees-terre-dauge/"
             target="_blank"
             className="cool-btn">Découvrir les randonnées</a>

          <p className="margin-top">Musées et découvertes</p>
          <p>Venez aussi découvrir à Livarot la fabrication des fameux fromages normands en particulier du
            Livarot surnommé le Colonel à la Fromagerie Grain d’orge.</p>
          <a target="_blank" className="cool-btn" href="https://www.graindorge.fr/visiter-la-fromagerie/">Visiter la
            fromagerie</a>

          <p className="margin-top">Cidrerie, chevaux, château et jardins...</p>
          <p>
            De nombreuses cidreries et distilleries aux alentours.
            C’est également le pays du cheval : une multitude de haras à proximité.
            Vous pouvez visiter la ville de Lisieux et sa basilique Sainte Thérèse, ou encore à proximité de
            Livarot, le château de Bellou et le Château de Kinnor à Fervaques. Ou un peu plus loin, le château de
            Saint Germain-de-Livet (à 14 minutes), le château de Vendeuvre (à 22 minutes) et vous perdre dans
            des jardins remarquables comme celui de Cambremer, de Mézidon-Canon...
          </p>
          <a target="_blank" className="cool-btn" href="https://www.authenticnormandy.fr/">Découvrir les activités</a>


          <p className="margin-top">Découverte insolite</p>
          <p>Au paradis des ânes : </p>
          <a target="_blank" className="cool-btn" href="https://www.auparadisdesanesdupaysdauge.fr/contact/">Découvrir
            le paradis des Ânes</a>

          <p className="margin-top">La mer</p>
          <p>La côte Fleurie avec Villers-sur-Mer, Deauville, Cabourg, Honfleur est à environ 30 minutes ainsi que
            les plages du débarquement.</p>
        </div>
      </div>
    </Layout>
  )
}

class App extends Component {
  componentWillMount() {
    document.title = "Le Manoir des Lords | Chambres d'hôtes";
  }


  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route title="" exact path="/" component={Home}/>
            <Route exact path="/chambre/:id" component={Bedroom}/>
            <Route exact path="/qui-sommes-nous" component={AboutUs}/>
            <Route exact path="/histoire-du-domaine" component={HouseHistory}/>
            <Route exact path="/activites" component={Activity}/>
            <Route component={NotFound}/>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
