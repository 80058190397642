import React, { Component } from 'react';

class Map extends Component {
    render() {
        return (
            <div className="mapGoogle">
                <h2 className="TitleHome">Trouvez nos gîtes</h2>
                <iframe title={'map'} src="https://maps.google.com/maps?q=La Pièce, 14140 Le Mesnil-Durand&t=&z=13&ie=UTF8&iwloc=&output=embed" width="100%" height="600" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
              <div style={{textAlign: "left"}}>
                <p>Nous sommes à 200km de Paris. Lisieux est à 14 km. Possibilité de prendre un bus (ligne 253, et 53) jusqu’à l’arrêt Pontallery non loin du manoir.</p>
                <p>Livarot et ses commerces sont à 4 km. Places de parking dans l’enceinte de la propriété.</p>
                <br/>
                <p><b>Informations diverses</b></p>
                <p>Chaque terrasse est aménagée avec chaises, tables, parasol et barbecue.</p>
                <p>Des jeux d’extérieurs sont mis à disposition :</p>
                <p>Table de ping-pong, toboggan et balançoire, filet de volley, et terrain de pétanque.</p>
                <br/>
                <p>Les animaux domestiques sont les bienvenus.</p>
                <p>Nos gîtes sont non fumeur.</p>
                <br/>
                <p>Linge de maison est fourni : draps, serviettes de toilette, torchons.</p>
                <p>Le ménage est inclus.</p>
                <p>L'entrée dans les lieux se fait à partir de 16h.</p>
                <br/>
                <p>Nos gîtes sont équipés : télévision, wifi, chauffage, lave-linge, lave-vaisselle, sèche cheveux et fer à repasser.</p>
                <p>L’équipement de base comprend : kit d’accueil avec dosettes café, thé, tisane, sucre, sel, poivre, savon, 1 rouleau papier toilette, sac poubelle et produits d’entretien.</p>
              </div>
            </div>
        )
    }
}

export default Map
