import React, { Component } from 'react'
import '../App.css'
import Layout from '../components/Layout'
import HeaderImageContainer from '../components/HeaderImageContainer'
import pin from '../assets/pin.svg'
import voyageurs from '../assets/voyageur.svg'
import lit from '../assets/lit.svg'
import bath from '../assets/bath.svg'
import Map from '../components/Map'

class Bedroom extends Component {
  constructor(props) {
    super(props)
    this.state = {
      top: 0
    }
  }

  componentWillMount() {

    window.scrollTo(0, 0)
    const {id} = this.props.match.params
    const elem = dataChambres.find((element) => {
      return element.id === id
    })
    if (elem) {
      document.title = `Chambres d'hôtes: ${elem.id}`
      this.setState({
        data: elem,
        days: 1,
        initialPrice: elem.prix,
        currentPrice: elem.prix
      })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false)
  }

  onScroll = () => {
    if (window.innerWidth < 600)
      return
    const top = window.pageYOffset || document.documentElement.scrollTop
    this.setState({top: top})
  }

  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      autoplay: false,
      autoplaySpeed: 3000,
      dots: false,
      pauseOnFocus: true,
      accessibility: true,
      arrows: true
    }
    console.log(this.state)
    return (
      <Layout>
        <HeaderImageContainer image={this.state.data.image} title={this.state.data.titleHeader}/>
        <div className="ctnChambre content">
          <div className="firstRow">
            <h1 className="TitleHome">{this.state.data.title}</h1>
            <div className="ctnInfo1">
              <div>
                <img src={pin} width={30} alt={'pin'}/>
                <p>Le Mesnil-Durand</p>
              </div>
              <div>
                <img src={voyageurs} width={50} alt={'voyageurs'}/>
                <p>{this.state.data.places} voyageurs</p>
              </div>
              <div>
                <img src={lit} width={30} alt={'lit'}/>
                <p>{this.state.data.lit}</p>
              </div>
              <div>
                <img src={bath} width={30} alt={'salle de bain'}/>
                <p>{this.state.data.bath}</p>
              </div>
            </div>
            <div className="CtnDesc">
              <p dangerouslySetInnerHTML={{__html: this.state.data.descLong}}/>
            </div>
            {/*<div className="ctnSlider">
              <div>
                <Slider {...settings}>
                  {this.state.data.sliderImg.map((e, i) => (
                    <div className="divSlider" key={i}>
                      <img src={e.url} width={'100%'} height={'auto'} className="imgSlider" alt={`img-${i}`}/>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>*/}
            <div className="gallery">
              {this.state.data.sliderImg.map((e, i) => (
                <div className="gallery__item gallery__item--1">
                  <img src={e.url} className="gallery__img" alt="Image 1"/>
                </div>
              ))}
            </div>
            <div className="CtnDesc">
              <Map/>
            </div>
          </div>
          <div className="secondRow" style={{
            position: this.state.top > 700 ? 'fixed' : 'relative',
            top: this.state.top > 700 ? '10%' : 'initial',
            right: this.state.top > 700 ? '15vw' : 'initial'
          }}>
            <div className="CtnbtnResa">
              <a className="apartirde">
                <p>Réserver par téléphone:</p>
                <p>0619731605</p>
              </a>
              <a className="apartirde">
                <p>Réserver par mail:</p>
                <p>emmanuelle.leclerc70@gmail.com</p>
              </a>
              <a className="btnResa" href={this.state.data.btnAirbnb} target="_blank">
                <button className="btnAirbnb">Réserver sur Airbnb</button>
              </a>
              <a className="btnResa" href={this.state.data.btnAbritel} target="_blank">
                <button className="btnAbritel">Réserver sur Abritel</button>
              </a>
            </div>
          </div>
        </div>
      </Layout>)
  }
}

const dataChambres = [{
  id: 'La-Maison-du-Jardinier',
  image: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-6.jpg',
  title: 'La Maison du Jardinier',
  titleHeader: 'Faites une pause dans la Maison du Jardinier du Manoir des Lords.',
  desc: 'Type d\'hébergement : Maison entière',
  descLong: 'Accolé à l’ancien potager, la maison du jardinier est un authentique bâtiment normand. Ces colombages, sa grande cheminée et ses larges ouvertures sur l’extérieur donnent tout son charme à cette petite maison. En plein cœur du pays d’Auge, votre gîte vous permettra de profiter du calme et d’une belle vue sur le bocage normand.<br/><br/> La maison est confortable.<br/> Au rez-de-chaussée<br/> Un salon lumineux avec une cheminée munie d’un poêle que vous pourrez utiliser, une cuisine ouverte sur une salle à manger et séparée du salon par quelques colombages.<br/><br/> A l’étage<br/> Une belle chambre ouverte avec un lit double et de l’autre côté de la cage d’escalier, une salle de bain équipée et une baignoire.<br/><br/> Il y a 2 couchages supplémentaires : 1 lit simple à l\'étage (à côté du lit double) et un lit simple au rez-de-chaussée.<br/><br/> Vous disposez d’une terrasse privative bien exposée pour prendre le soleil. Elle est aménagée de table, chaises et parasol pour des barbecues sympathiques.',
  places: 4,
  lit: '1 chambre',
  bath: '1 salle de bain privée',
  btn: 'En savoir plus',
  btnAirbnb: 'https://www.airbnb.fr/rooms/833797866048954566?check_in=2023-03-26&check_out=2023-03-30&guests=1&adults=2&children=2&s=67&unique_share_id=72f62922-eb74-4a3f-8c28-aa3d7df8702d',
  btnAbritel: 'https://www.abritel.fr/location-vacances/p2226531?uni_id=2402466',
  prix: '110',
  sliderImg: [{
    url: 'https://a0.muscache.com/im/pictures/107c7451-e290-4faa-bc4b-c7f21734cb8f.jpg?aki_policy=xx_large'
  }, {
    url: 'https://a0.muscache.com/im/pictures/04581788-eec1-484d-8081-e41e063348a8.jpg?aki_policy=x_large'
  }, {
    url: 'https://a0.muscache.com/im/pictures/040e7adc-49bf-431f-8f86-7e86d6165070.jpg?aki_policy=x_large'
  }, {
    url: 'https://a0.muscache.com/im/pictures/79ab62bd-4371-4a2c-be5d-73e1bab5db83.jpg?aki_policy=x_large'
  }, {
    url: 'https://a0.muscache.com/im/pictures/b10550c2-ef72-4cb8-8e5c-204eb4fee735.jpg?aki_policy=x_large'
  }, {
    url: 'https://a0.muscache.com/im/pictures/a316b2b6-358f-4ca4-9d4b-770b302afc34.jpg?aki_policy=x_large'
  }, {
    url: 'https://a0.muscache.com/im/pictures/0ed9183c-03e1-4b3d-92f3-bee4e6a18150.jpg?aki_policy=x_large'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-1.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-2.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-3.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-4.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-5.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-6.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-7.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-8.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-9.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-10.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-11.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-12.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-13.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-14.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-15.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-16.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-17.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-18.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-19.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-20.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-21.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-22.jpg'
  }],
  included: ['Entrée privée', 'Terasse privée', 'Barbecue', 'Salon de jardin', 'Équipement de base (Savon, serviettes, draps et papier toilette)', 'Cheminée', 'Chauffage', 'Télévision', 'Lave-linge gratuit', 'Fer à repasser', 'Wifi', 'Parking sur place', 'Cuisine', 'Sèche-cheveux', 'Cintres'],
  not_included: ['Climatisation', 'Shampoing'],
  safety_standards: ['Détécteur de fumée', 'Détecteur de monoxyde de carbone'],
  rules: ['Non fumeur', 'L\'entrée dans les lieux se fait à partir de 15:00'],
  caution: 'Caution - en cas de dommages matériels dans le logement, vous pouvez avoir à payer jusqu\'à 100€'
}, {
  id: 'La-Sellerie-du-Manoir-de-Pontallery',
  image: 'https://a0.muscache.com/im/pictures/a15d2676-2337-4048-87ec-230ac8c88c7f.jpg?aki_policy=x_large',
  title: 'La Sellerie',
  titleHeader: 'Faites une pause dans la Sellerie du Manoir des Lords.',
  desc: 'Type d\'hébergement : Maison entière',
  descLong: 'Ce gîte a été aménagé dans l\'ancienne sellerie du Manoir. <br/><br/>Il peut accueillir 5 voyageurs avec une possibilité de 2 couchages supplémentaires dans le canapé-lit du salon. <br/>Rez-de-chaussée Grand séjour avec un salon, une salle à manger et une cuisine équipée et une salle de bain avec une douche et des toilettes. <br/><br/>A l’étage 2 grandes chambres : l’une avec un lit double en 140 cm, l’autre avec deux lits simples 2x 90cm. Et, sur le palier, un autre coin chambre avec un lit simple. <br/>Une salle de bain avec une baignoire. <br/><br/>Vous disposez d’une terrasse privative bien exposée pour prendre le soleil. Elle est aménagée de table, chaises et parasol pour des barbecues sympathiques. <br/>Un petit potager planté d\'herbes aromatiques, tomates cerises, rhubarbe dont vous pouvez vous régaler...',
  places: 5,
  lit: '2 chambres',
  bath: '2 salle de bain privée (douche et bain)',
  btn: 'En savoir plus',
  btnAirbnb: 'https://www.airbnb.fr/rooms/833938682168443458?adults=5&children=2&infants=0&location=Normandy%2C%20France&check_in=2023-03-01&check_out=2023-03-06&source_impression_id=p3_1677491269_ygsVj3YKJF9WJDJp',
  btnAbritel: 'https://www.abritel.fr/location-vacances/p2228416?uni_id=2404351',
  prix: '140',
  sliderImg: [{
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/sellerie/sellerie-1.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/sellerie/sellerie-2.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/sellerie/sellerie-3.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/sellerie/sellerie-4.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/sellerie/sellerie-5.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/sellerie/sellerie-6.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/sellerie/sellerie-7.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/sellerie/sellerie-8.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/sellerie/sellerie-10.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/sellerie/sellerie-11.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/sellerie/sellerie-12.jpg'
  }, {
    url: 'https://a0.muscache.com/im/pictures/ff48a32d-e241-432f-b647-25c4a5955bf5.jpg?aki_policy=x_large'
  }, {
    url: 'https://a0.muscache.com/im/pictures/ce7a91ed-8cc5-4e70-9248-b9cea96c5976.jpg?aki_policy=x_large'
  }, {
    url: 'https://a0.muscache.com/im/pictures/6d2d1350-215a-498a-bdc4-7ded9d16e514.jpg?aki_policy=x_large'
  }],
  included: ['Entrée privée', 'Terasse privée', 'Barbecue', 'Salon de jardin', 'Équipement de base (Savon, serviettes, draps et papier toilette)', 'Chauffage', 'Télévision', 'Lave-linge', 'Fer à repasser', 'Wifi', 'Parking sur place', 'Cuisine', 'Sèche-cheveux', 'Cintres'],
  not_included: ['Climatisation', 'Shampoing'],
  safety_standards: ['Détécteur de fumée', 'Détécteur de monoxyde de carbone'],
  rules: ['Non fumeur', 'L\'entrée dans les lieux se fait à partir de 15:00'],
  caution: 'Caution - en cas de dommages matériels dans le logement, vous pouvez avoir à payer jusqu\'à 100€'
}, {
  id: 'L-atelier-du-Manoir-des-Lords',
  image: 'https://a0.muscache.com/im/pictures/96a89f40-cba9-480b-b8df-979a638a2650.jpg?aki_policy=x_large',
  title: 'L\'Atelier',
  titleHeader: 'Faites une pause dans l\'Atelier du Manoir des Lords.',
  desc: 'Type d\'hébergement : Maison entière',
  descLong: 'L’atelier, à l’origine bûcher ouvert de part et d’autre, est un bâtiment dont la structure de charpente innovante, permettant le passage des nouveaux engins agricoles, était exposée à l’Exposition Universelle de 1900. <br/><br/>Ce gîte a été remis à neuf récemment dans un esprit loft. La maison est confortable. Sa décoration est épurée. <br/>Il peut accueillir 2 personnes. Deux couchages supplémentaires sont possibles dans le canapé-lit du salon. <br/>Il est isolé et vous avez une belle et large vue sur les bocages du Pays d’Auge. <br/><br/>Rez-de-chaussée Grand espace de vie : <br/>une cuisine spacieuse donnant sur une table de salle à manger et un grand coin salon. <br/><br/>A l’étage, une chambre lumineuse ouverte avec un lit double, une salle de bain équipée avec douche et toilette. <br/>L’atelier s’ouvre sur une petite terrasse privative, qui vous offrira la vue d’un magnifique panorama sur toute la campagne. <br/>Cette terrasse est aménagée de table, chaises et parasol pour des barbecues sympathiques.',
  places: 2,
  lit: '1 chambre',
  bath: '1 salle de bain privée',
  btn: 'En savoir plus',
  btnAirbnb: 'https://www.airbnb.fr/rooms/833922268127683963?adults=2&children=2&infants=0&pets=0&check_in=2023-03-24&check_out=2023-03-29&federated_search_id=cef9a9b5-b79c-488f-807a-f64bf34777a3&source_impression_id=p3_1677490717_MVcBC%2BqMsEsM5in6',
  btnAbritel: 'https://www.abritel.fr/location-vacances/p2227971?uni_id=2403906',
  prix: '110',
  sliderImg: [{
    url: 'https://a0.muscache.com/im/pictures/96268295-ab01-43f9-b9c6-ea69983bd127.jpg?aki_policy=xx_large'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/atelier/atelier-1.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/atelier/atelier-2.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/atelier/atelier-3.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/atelier/atelier-4.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/atelier/atelier-5.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/atelier/atelier-6.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/atelier/atelier-7.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/atelier/atelier-8.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/atelier/atelier-9.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/atelier/atelier-10.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/atelier/atelier-11.jpg'
  }],
  included: ['Terasse privée', 'Barbecue', 'Salon de jardin', 'Équipement de base (Savon, serviettes, draps et papier toilette)', 'Chauffage', 'Télévision', 'Lave-linge', 'Fer à repasser', 'Wifi', 'Parking sur place', 'Cuisine', 'Sèche-cheveux', 'Cintres'],
  not_included: ['Climatisation', 'Shampoing'],
  safety_standards: ['Détécteur de fumée', 'Détécteur de monoxyde de carbone'],
  rules: ['Non fumeur', 'L\'entrée dans les lieux se fait à partir de 15:00'],
  caution: 'Caution - en cas de dommages matériels dans le logement, vous pouvez avoir à payer jusqu\'à 100€'
}, {
  id: 'Le-pressoir-du-Manoir-des-Lords',
  image: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/14.jpg',
  title: 'Le Pressoir',
  titleHeader: 'Faites une pause dans le Pressoir du Manoir des Lords.',
  desc: '',
  descLong: 'Belle maison de caractère, ce gîte à colombages est spacieux et idéal pour des familles ou un groupe. Situé au cœur du pays d’Auge, il a été aménagé dans l’ancien pressoir du manoir de Pontallery.' +
    '<br/>La maison, indépendante, est confortable et peut accueillir 10 personnes avec 2 couchages supplémentaires possibles dans le canapé-lit du salon.<br/><br/>' +
    'Au rez-de-chaussée<br/>' +
    'Un grand séjour avec un salon et une salle à manger, une grande cuisine, une chambre avec 2 lits simples (2x90cm) et une salle d’eau avec une douche et des toilettes.<br/>' +
    'À l’étage<br/><br/>' +
    'Il y a deux chambres avec un lit double chacune (1 lit en 140 cm et 1 lit en 160 cm) une autre chambre avec 4 lits simples (4x90cm) et une salle de bain séparée et équipée avec une baignoire et des toilettes.<br/><br/>' +
    '\n' +
    'Une terrasse privative bien exposée vous permettra de prendre le soleil. Elle est aménagée de table, chaises et parasol pour des barbecues sympathiques.<br/><br/>' +
    'Ce bâtiment date du XVIème siècle et contenait autrefois un véritable pressoir, machine agricole utilisée pour extraire par pression le jus des pommes avant de devenir cidre ou calvados !<br/>' +
    'Si vous venez au printemps, les pommiers dans le parc et les champs alentours seront en fleurs.',
  places: 10,
  lit: '4 chambres',
  bath: '2 salles de bains',
  btn: 'En savoir plus',
  btnAirbnb: 'https://www.airbnb.fr/rooms/833964165162224164?preview_for_ml=true&source_impression_id=p3_1686257758_Ghlfqq%2BPwqrELlPu',
  btnAbritel: 'https://www.abritel.fr/location-vacances/p2228406?uni_id=2404341',
  prix: '110',
  sliderImg: [{
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/14.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/2.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/pressoir-18.JPG'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/pressoir-19.JPG'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/pressoir-20.JPG'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/pressoir-21.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/13.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/pressoir-22.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/15.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/1.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/3.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/4.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/5.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/6.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/8.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/9.jpeg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/10.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/11.jpg'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/pressoir-16.JPG'
  }, {
    url: 'https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/pressoir-17.JPG'
  }],
  caution: 'Caution - en cas de dommages matériels dans le logement, vous pouvez avoir à payer jusqu\'à 100€'
}]


export default Bedroom
