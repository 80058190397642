import React from 'react'
import Slider from 'react-slick'
import stars from '../assets/stars.svg'

function Stars(number) {
  const array = []
  for (let i = 0; i < number; i++) {
    array.push(<img key={i} src={stars} width={30}/>)
  }
  return array
}

const Testimonials = () => {
  const data = [{
    com: 'La maison est très jolie, elle est exactement comme sur les photos.\n' +
      'François que nous avons pu rencontrer, est malgré ses débuts, très à l\'ecoute de nos ressentis, et de nos besoins. Il se montre disponible, et réactif.\n' +
      'La maison et le mobilier étaient propres, la cuisine très bien équipée !\n' +
      'Bref, un super séjour au calme, paisible et reposant... Merci à vous !\n',
    lieu: '',
    date: 'Mai 2023',
    stars: 5
  }, {
    com: 'Gîte spacieux et très agréable avec une grande terrasse ensoleillée ! Il y a même un potager privatif, on réfléchit déjà à revenir cet été pour en profiter... Et refaire des belles balades dans ce cadre superbe !\n' +
      'C\'était très propre, les chambres sont grandes et très sympa!\n' +
      'Emmanuelle a été très réactive et nous a accueilli chaleureusement à l\'heure convenue',
    lieu: '',
    date: 'Avril 2023',
    stars: 5
  }, {
    com: 'Nous avons beaucoup apprécié le cadre, la vue, les activités sur le terrain de ce magnifique pressoir réaménagé. Nous avons pu profiter de la terrasse ensoleillée et fleurie. L\'ensemble correspondait parfaitement à nos attentes! Par ailleurs, Caroline et François ont pensé à une petite attention de bienvenue (merci!). Emmanuelle nous a très bien accueillis et donné de bons conseils. On recommande chaleureusement!',
    lieu: '',
    date: 'Mai 2023',
    stars: 5
  }, {
    com: 'Séjour au top, environnement magnifique, logement agréable et propre.\n' +
      'Super accueil, nous avons passé un très bon weekend.',
    lieu: '',
    date: 'Mai 2023',
    stars: 5
  }]
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  }

  return (
    <div>
      <h2 className="TitleHome" style={{textAlign: 'center'}}>Les avis de nos clients</h2>
      <Slider {...settings}>
        {data.map((e, i) => (
          <div key={i} className="Testimonials">
            <h2>{e.lieu}</h2>
            <h3>{e.com}</h3>
            <div className="LieuDate">
              <p>{e.date}</p>
            </div>
            <div className="ctnStars">
              {Stars(e.stars)}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Testimonials
