import React, { Component } from 'react';
import Layout from '../components/Layout';
import '../App.css';
import manoir2 from '../assets/IMG_3670 (1).JPG';
import { Link } from "react-router-dom";

import Testimonials from '../components/Testimonials';
import HeaderImageContainer from "../components/HeaderImageContainer";
import Map from "../components/Map";

const dataChambres = [{
  id: "La-Maison-du-Jardinier",
  image: "https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/jardinier/jardi-20.jpg",
  title: "La Maison du Jardinier",
  desc: "Type d'hébergement : Maison",
  prix: "110€ sans petit déjeuner",
  btn: "En savoir plus"
}, {
  id: "La-Sellerie-du-Manoir-de-Pontallery",
  image: "https://a0.muscache.com/im/pictures/a15d2676-2337-4048-87ec-230ac8c88c7f.jpg?aki_policy=x_large",
  title: "La Sellerie",
  desc: "Type d'hébergement : Maison",
  prix: "140€ sans petit déjeuner",
  btn: "En savoir plus"
}, {
  id: "L-atelier-du-Manoir-des-Lords",
  image: "https://a0.muscache.com/im/pictures/96268295-ab01-43f9-b9c6-ea69983bd127.jpg?aki_policy=large",
  title: "L'Atelier",
  desc: "Type d'hébergement : Maison",
  prix: "110€ sans petit déjeuner",
  btn: "En savoir plus"
}, {
  id: "Le-pressoir-du-Manoir-des-Lords",
  image: "https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/pressoir/14.jpg",
  title: "Le Pressoir",
  desc: "",
  prix: "110€ sans petit déjeuner",
  btn: "En savoir plus"
}, {
  id: "gitedegroupe",
  image: "https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/all-gites.jpeg",
  title: "Louer la totalité des gîtes du Manoir des Lords",
  desc: "",
  prix: "",
  btn: "En savoir plus"
}];

class Home extends Component {
  componentWillMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Layout>
        <HeaderImageContainer image={"https://lemanoirdeslords.s3.eu-west-3.amazonaws.com/manoir-01.JPG"}
                              title={"Venez vivre un séjour inoubliable au Manoir des Lords en Normandie près de Livarot."}/>
        <div className="content">
          <h1 className="TitleHome">
            Nos gîtes
          </h1>
          <p style={{textAlign: 'center'}}>
            Venez goûter à la quiétude des lieux et au ressourcement de la campagne. <br/>Nos gîtes se trouvent coeur d’un charmant parc arboré de plus de 2 hectares, dominant le bocage normand.<br/>
            Chaque logis, doté d’une terrasse privée aménagée, a son indépendance complète et préservera ainsi votre intimité.<br/>
            Prenez le temps d’une halte bénéfique au vert.<br/>
          </p>
          <div className="CtnList">
            {dataChambres.map((e, i) => (
              e.id === 'gitedegroupe' ? <a style={{visibility: i === 4 ? 'visible' : 'visible'}}
                                           href="https://www.gitedegroupe.fr/gite-groupe-dy-8876.html" className="Card"
                                           target="_blank">
                  <div className="ctnImg">
                    <img src={e.image}/>
                  </div>
                  <div className="CardContent">
                    <p><strong>{e.title}</strong></p>
                    <button id="avisclients">{e.btn}</button>
                  </div>
                </a> :
                <Link key={i} to={`/chambre/${e.id}`} className="Card">
                  <div className="ctnImg">
                    <img src={e.image}/>
                  </div>
                  <div className="CardContent">
                    <p><strong>{e.title}</strong></p>
                    <button id="avisclients">{e.btn}</button>
                  </div>
                </Link>
            ))}
          </div>
          <Testimonials/>
          <Map/>
        </div>
      </Layout>
    )
  }
}

export default Home;
