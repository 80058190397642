import React, { Component } from 'react';
import logo from '../assets/logo1.jpg';
import airbnblogo from '../assets/airbnb-logo.png';

class Footer extends Component {
    render() {
        return (
            <footer id="footer">
                <div className="content App-footer">
                    <div className="ResaFooterCtn">
                        <img src={logo} height={50} alt="logo"/>
                        <p id="para">Le Manoir des Lords - TOUS DROITS RÉSERVÉS</p>
                    </div>
                    <div>
                        <p className="nousContacter">Tel: <a href="tel:+0619731605">0619731605</a> Email: <a href="mailto:emmanuelle.leclerc70@gmail.com">emmanuelle.leclerc70@gmail.com</a></p>
                    </div>
                    <div className="ResaFooterCtn">
                        <p>Réserver sur</p> <a href="https://www.airbnb.fr/users/show/502565220" target="_blank" rel="noopener noreferrer"><img src={airbnblogo} height={40} alt="airbnbLogo"/></a>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer
