import React from 'react';
import ScrollTo from './ScrollTo';
import arrow from '../assets/arrowDown.svg';

const HeaderImageContainer = (props) => {
    const image = props.image;

    return (
        <div className="HeaderImg">
          {/*<h1 className="Accroche content" dangerouslySetInnerHTML={{__html: props.title}}/>*/}
            <div className="HeaderImg hero__bg">
                <img className="hero__bg1" src={image} width={'100%'} alt="cover"/>
            </div>
          {/*<ScrollTo>
                <div className="btnRound" id="chambres">
                    <img className="imgArrow" src={arrow} alt="arrowDown"/>
                </div>
            </ScrollTo>*/}
        </div>
    );
}

export default HeaderImageContainer;
