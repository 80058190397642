import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
// import logo from '../assets/logo.png';
import logo1 from '../assets/logo1.jpg'
import airbnblogo from '../assets/airbnb-logo.png';


class Header extends Component {
    state = {
        isToggleOn: false
    }
    handleClick = () => {
        this.setState(prevState => ({
            isToggleOn: !prevState.isToggleOn
        }));
    }
    render() {
        return (
            <header className="App-header">
                <div className="contentNav">
                    <a href='/' className="logo-format">
                        <p className="man-title">Les gîtes du Manoir des Lords</p>
                    </a>
                    <div className="menuList" style={{display: window.innerWidth < 600 ? "none" : "flex"}}>
                        <a href={'/#chambres'}><p className="cool-link">Nos gîtes</p></a>
                        <a href={'/#avisclients'}><p className="cool-link">Avis clients</p></a>
                        <NavLink activeStyle={{ color: '#0FF2AC' }} to={'/qui-sommes-nous'}><p className="cool-link">Qui sommes nous ?</p></NavLink>
                        <NavLink activeStyle={{ color: '#0FF2AC' }} to={'/histoire-du-domaine'}><p className="cool-link">Histoire du domaine</p></NavLink>
                        <NavLink activeStyle={{ color: '#0FF2AC' }} to={'/activites'}><p className="cool-link">Quelles activités ?</p></NavLink>
                      {/*<NavLink activeStyle={{ color: '#0FF2AC' }} to={'/location-évènement-lemanoirdeslords'}><p className="cool-link">Évènement</p></NavLink>*/}
                    </div>
                    {window.innerWidth < 600 &&
                    <button onClick={() => this.handleClick()} class={`hamburger hamburger--collapse ${this.state.isToggleOn ? "is-active" : ""}`} type="button">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
                    </button>}
                    {window.innerWidth < 600 &&
                    <div id="navigation" className="navMobile" style={{display: this.state.isToggleOn ? "block" : "none"}}>
                        <div className="menuList">
                            <a onClick={() => this.handleClick()} href={'/#chambres'}><p className="cool-link">Nos gîtes</p></a>
                            <a onClick={() => this.handleClick()} href={'/#avisclients'}><p className="cool-link">Avis clients</p></a>
                            <NavLink activeStyle={{ color: '#0FF2AC' }} to={'/qui-sommes-nous'}><p className="cool-link">Qui sommes nous ?</p></NavLink>
                            <NavLink activeStyle={{ color: '#0FF2AC' }} to={'/histoire-du-domaine'}><p className="cool-link">Histoire du domaine</p></NavLink>
                            <NavLink activeStyle={{ color: '#0FF2AC' }} to={'/activites'}><p className="cool-link">Quelles activités ?</p></NavLink>
                            <div className="ResaFooterCtn">
                                <p>Réserver sur</p> <a href="https://www.airbnb.fr/users/show/502565220" target="_blank" rel="noopener noreferrer"><img src={airbnblogo} height={40} alt={'airbnbLogo'}/></a>
                            </div>
                        </div>
                    </div>}
                </div>
            </header>
        )
    }
}

export default Header
